import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
export const _frontmatter = {
  "title": "Sure smells like updog in here",
  "path": "/updog",
  "date": "2021-02-27T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className="mb-8">test in section?</section>
    <h1>{`Nothing, what's up with you?`}</h1>
    <p>{`I miss when life and things were simpler, not like way back pre-electricity times
no, I'm talking more about the times we used to have where people had "thick-skin",
and sticking up for someone didn't shame them in ways I still don't understand.`}</p>
    <p>{`The office was simple once, I liked that.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      